'use client';

import { CheckFilledIcon, ExclamationFilledIcon, ExclamationTriangleFilledIcon, InfoFilledIcon } from '@aiq/icons/components';
import type { ChangeEventHandler, ReactNode, FormEvent, HTMLProps } from 'react';
import { forwardRef } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { cn } from '../utils';

interface FormProps<T extends HTMLElement> extends HTMLProps<T> {
	onChange?: ChangeEventHandler<T>;
	title?: string;
	className?: string;
	name?: string;
	error?: string | boolean | null;
	warning?: string;
	description?: string;
}

interface SelectProps extends FormProps<HTMLSelectElement> {
	value: string;
	children: ReactNode;
}

interface RadioProps extends FormProps<HTMLInputElement> {
	onChange: ChangeEventHandler;
	value: string | number;
	title: string;
	name: string;
	checked: boolean;
}

interface CheckboxProps extends FormProps<HTMLInputElement> {
	checked: boolean;
	value?: string | number;
}

interface InputProps extends FormProps<HTMLInputElement> {
	min?: number | string;
	max?: number | string;
	append?: string | ReactNode;
	type?: string;
	placeholder?: string;
	disabled?: boolean;
	onBlur?: (e: FormEvent<HTMLInputElement>) => void;
	list?: string;
	thousandSeparator?: boolean;
	icon?: ReactNode;
	rightView?: ReactNode;
}

interface TextareaProps extends FormProps<HTMLTextAreaElement> {
	rows?: number;
	textAreaClassName?: string;
}

interface ErrorMessage {
	text?: string;
	children?: ReactNode;
	hideIcon?: boolean;
	className?: string;
}

interface FormTitleProps {
	title?: string;
	className?: string;
	children?: ReactNode;
}

type NumberInputProps = Omit<InputProps, 'defaultValue'> & NumericFormatProps;

export const inputClassNamesBase =
	'focus:ring-0 focus:border-primary outline-none bg-input border-neutral-100 appearance-none rounded-lg basis-[38px] h-[38px] text-[13px] text-neutral-700 disabled:cursor-not-allowed disabled:bg-slate-300 justify-start hover:bg-input';

export const classNameForInputProps = ({ error, title, className = '', append, rightView }: InputProps) => {
	const errorClassName = error ? 'border-error-red' : '';

	let _className = cn('w-full px-2 flex-1', inputClassNamesBase, className, errorClassName);

	if (Boolean(title) && !append && !rightView) {
		_className = cn('form-input px-2 w-full', inputClassNamesBase, errorClassName);
	} else if (append) {
		_className = `${inputClassNamesBase} form-input flex-1 focus:ring-0 text-right pr-2 w-full h-full  bg-transparent border-none appearance-none outline-none ${errorClassName}`;
	} else if (rightView) {
		_className = `${inputClassNamesBase} form-input flex-1 focus:ring-0 pl-2 pr-2 w-full h-full bg-transparent border-none appearance-none outline-none ${errorClassName}`;
	}

	return _className;
};

export function DescriptionText({ text, children, skipIcon = false, className = '' }: { text?: string; className?: string; skipIcon?: boolean; children?: ReactNode }) {
	return (
		<div className={`flex flex-row justify-start leading-5 ${className}`}>
			{!skipIcon ? (
				<span className='flex flex-col items-start justify-center h-[20px]'>
					<InfoFilledIcon className='h-3.5 w-3.5 mr-2 text-neutral-500' />
				</span>
			) : null}

			<span className='text-xs self-start leading-[20px] text-neutral-500'>{text || children}</span>
		</div>
	);
}

export function WarningMessage({ text, className = '' }: ErrorMessage) {
	return (
		<div className={`mt-1 flex flex-row justify-start leading-5 ${className}`}>
			<span className='flex flex-col items-start justify-center h-[20px]'>
				<ExclamationTriangleFilledIcon className='h-3.5 w-3.5 mr-2 text-yellow-400' />
			</span>
			<span className='text-yellow-600 text-xs leading-[20px]'>{text}</span>
		</div>
	);
}

export function ErrorMessage({ text, children, className = '', hideIcon = false }: ErrorMessage) {
	return (
		<div className={`mt-1 flex flex-row items-center ${className}`}>
			{!hideIcon ? <ExclamationFilledIcon className='h-3 w-3 mr-2 text-error-red' /> : null}
			<small className='text-error-red text-xs'>{text || children}</small>
		</div>
	);
}

export function InfoMessage({ text, className = '' }: { text: string; className?: string }) {
	return (
		<div className={`flex flex-row justify-start leading-5 ${className}`}>
			<span className='flex flex-col items-start justify-center h-[20px]'>
				<InfoFilledIcon className='h-3.5 w-3.5 mr-2 text-primary-500' />
			</span>

			<span className='text-xs self-start leading-[20px] text-primary-700'>{text}</span>
		</div>
	);
}

export function SuccessMessage({ text, children, className = '' }: { children?: ReactNode; text?: string; className?: string }) {
	return (
		<div className={`flex flex-row justify-start leading-5 ${className}`}>
			<span className='flex flex-col items-start justify-center h-[20px]'>
				<CheckFilledIcon className='h-3.5 w-3.5 mr-2 text-green-500' />
			</span>

			<span className='text-xs self-start leading-[20px] text-green-700'>{text || children}</span>
		</div>
	);
}

export function FormTitle({ title, className = '', children, ...props }: FormTitleProps) {
	const _children = typeof title === 'string' ? title.toUpperCase() : children;

	return (
		<label className={`block tracking-wide text-xs text-gray-400 mb-2 ${className}`} {...props}>
			{typeof _children === 'string' ? _children.toUpperCase() : children}
		</label>
	);
}

export function Radio({ onChange, value = '', title = '', name = '', className = '', ...props }: RadioProps) {
	return (
		<div className={`flex flex-row items-center ${className}`}>
			<label className='mr-1 text-xs cursor-pointer' htmlFor={`${name}_${title.toLowerCase()}`}>
				{title}
			</label>
			<input className='!outline-none cursor-pointer focus:ring-0' id={`${name}_${title.toLowerCase()}`} name={name} onChange={onChange} type='radio' value={value} {...props} />
		</div>
	);
}

export function Select({ onChange, title, name, value, className, children, error, ...rest }: SelectProps) {
	const errorClassName = error ? 'border-error-red' : '';

	let component = (
		<select className={`form-select px-2 flex-1 cursor-pointer ${inputClassNamesBase} ${className} ${errorClassName}`} name={name} onChange={onChange} value={value} {...rest}>
			{children}
		</select>
	);

	if (title) {
		component = (
			<div className={`flex flex-col cursor-pointer ${className}`}>
				<label className='text-xs tracking-wide whitespace-nowrap text-gray-400 mb-2'>{title.toUpperCase()}</label>
				<select className={`form-select px-2 flex-1 cursor-pointer ${inputClassNamesBase} ${errorClassName}`} name={name} onChange={onChange} value={value} {...rest}>
					{children}
				</select>
			</div>
		);
	}

	return (
		<>
			{component}
			{error && typeof error === 'string' ? <ErrorMessage text={error} /> : null}
		</>
	);
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox({ name = '', title = '', checked = false, onChange, className = '', ...props }, ref) {
	return (
		<div className={` flex flex-row flex-1 items-center mb-2 ${className}`}>
			<input ref={ref} checked={checked} className='peer cursor-pointer disabled:cursor-not-allowed' id={name} name={name} onChange={onChange} type='checkbox' {...props} />
			<label className='tracking-wide ml-2 text-[13px] cursor-pointer peer-disabled:cursor-not-allowed' htmlFor={name}>
				{title}
			</label>
		</div>
	);
});

function BaseInput({ children, append = '', className = '', title = '', error, description, rightView, icon, warning }: InputProps & { children: JSX.Element }) {
	let component: JSX.Element | null = children;

	if (Boolean(title) && !append && !rightView) {
		component = (
			<div className={`flex flex-1 flex-col ${className}`}>
				<label className='text-xs tracking-wide text-gray-400 mb-2'>{title.toUpperCase()}</label>
				{children}
			</div>
		);
	} else if (append) {
		component = (
			<div className={`flex flex-col ${className}`}>
				{title ? <FormTitle title={title} /> : null}
				<div className='px-2 pr-3 flex flex-row items-center flex-1 bg-input outline-none rounded-lg text-default-text text-[13px] basis-[38px] h-[38px]  focus-within:border-primary border'>
					{children}
					{append ? <span className='ml-auto border-left border-l pl-3 border-slate-400 opacity-60'>{append}</span> : null}
				</div>
			</div>
		);
	} else if (rightView) {
		component = (
			<div className={`flex flex-col ${className}`}>
				{title ? <FormTitle title={title} /> : null}
				<div className='relative flex flex-row items-center flex-1 bg-input rounded-lg text-default-text text-[13px] basis-[38px] h-[38px] border-neutral-100 focus-within:border-primary border'>
					{children}
					{rightView ? <span className='inline-flex ml-auto absolute right-0 top-0 h-[36px] flex items-center justify-center w-[36px]'>{rightView}</span> : null}
				</div>
			</div>
		);
	}

	return (
		<div className='w-full'>
			{component}
			{description ? <DescriptionText className='mt-1' text={description} /> : null}
			{error && typeof error === 'string' ? <ErrorMessage text={error} /> : null}
			{warning && typeof warning === 'string' ? <WarningMessage text={warning} /> : null}
		</div>
	);
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(function NumberInput(props: NumberInputProps, ref) {
	return <NumericFormat getInputRef={ref} thousandSeparator {...props} customInput={Input} type='text' />;
});

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(_props: InputProps, ref) {
	const { onChange, description, rightView, thousandSeparator, ...props } = _props;

	return (
		<BaseInput {..._props} thousandSeparator={thousandSeparator} rightView={rightView}>
			<input onChange={onChange} ref={ref} {...props} className={classNameForInputProps(_props)} />
		</BaseInput>
	);
});

export function Textarea({ className = '', title = '', textAreaClassName = '', ...props }: TextareaProps) {
	if (title) {
		return (
			<div className={`flex flex-col ${className}`}>
				<label className='text-xs tracking-wide text-gray-400 mb-2'>{title.toUpperCase()}</label>
				<textarea
					{...props}
					className={`px-2 py-2 resize-none flex-1 bg-input rounded-lg text-default-text h-full text-[13px] outline-none ${inputClassNamesBase} ${textAreaClassName}`}
				/>
			</div>
		);
	}
	return (
		<textarea
			{...props}
			className={`${inputClassNamesBase} form-input flex-1 focus:ring-0 pl-2 pr-2 w-full h-full bg-transparent resize-none  appearance-none outline-none ${className} 
		`}
		/>
	);
}
