import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgProductsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 23' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M16.667 2.083c.382 0 .694.313.694.695v5.555h2.083V2.778A2.78 2.78 0 0 0 16.667 0H8.333a2.78 2.78 0 0 0-2.777 2.778v5.555h2.083V2.778c0-.382.312-.695.694-.695h2.43v2.778c0 .382.313.695.695.695h2.084a.696.696 0 0 0 .694-.695V2.083zm-2.448 20.14h8.003A2.78 2.78 0 0 0 25 19.443V12.5a2.78 2.78 0 0 0-2.778-2.778H14.22a4.13 4.13 0 0 1 1.002 2.084h1.098v2.777c0 .382.313.695.695.695h2.083a.696.696 0 0 0 .695-.695v-2.777h2.43c.382 0 .695.312.695.694v6.944a.696.696 0 0 1-.695.695h-7a4.17 4.17 0 0 1-1.003 2.083M11.11 11.805c.382 0 .695.312.695.694v6.944a.696.696 0 0 1-.695.695H2.778a.696.696 0 0 1-.695-.695V12.5c0-.382.313-.694.695-.694h2.43v2.777c0 .382.313.695.695.695h2.083a.696.696 0 0 0 .695-.695v-2.777zM2.778 9.722A2.78 2.78 0 0 0 0 12.5v6.944a2.78 2.78 0 0 0 2.778 2.778h8.333a2.78 2.78 0 0 0 2.778-2.778V12.5a2.78 2.78 0 0 0-2.778-2.778z'
			/>
		</svg>
	);
});
export default memo(SvgProductsIcon);
