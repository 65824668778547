'use client';

import * as React from 'react';
import { AngleLeftIcon, AngleRightIcon } from '@aiq/icons/components';
import { DayPicker, DayPickerProps } from 'react-day-picker';

import { cn } from '../utils';
import Button, { classNameForOptions } from './button';
import { type Locale, format } from 'date-fns';

export type CalendarProps = DayPickerProps & { captionFormat?: string; required?: boolean; locale?: Locale };

function Calendar({ className, classNames, showOutsideDays = true, captionFormat = 'LLLL yyyy', locale, startMonth, endMonth, hidden, ...props }: CalendarProps) {
	return (
		<DayPicker
			mode='single'
			showOutsideDays={showOutsideDays}
			disabled={props.disabled}
			formatters={{
				formatCaption: (date: Date) => {
					return format(date, captionFormat, { locale });
				}
			}}
			locale={locale}
			className={cn('p-3', className)}
			classNames={{
				months: 'relative',
				month: 'space-y-4',
				month_caption: 'flex justify-center pt-1 relative items-center h-8',
				caption_label: 'text-sm font-medium capitalize',
				nav: 'space-x-1 flex items-center  absolute h-8 w-full',
				month_grid: 'w-full border-collapse space-y-1',
				weekdays: 'flex',
				weekday: 'text-neutral-600 rounded-md w-9 font-[500] text-[0.8rem]',
				week: 'flex w-full mt-2',
				day: 'h-10 w-10 hover:bg-neutral-50 rounded-md text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
				day_button: cn('h-10 w-10 p-0  font-normal shadow-none aria-selected:opacity-100'),
				range_end: 'day-range-end',
				selected: 'rounded-md bg-black !text-white hover:bg-black/75 focus:bg-primary-500 focus:text-primary-foreground',
				today: 'bg-neutral-50 border-none font-bold rounded-md',
				outside:
					'day-outside cursor-disabled select-none text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-100 text-neutral-300',
				disabled: 'hover:bg-transparent text-neutral-300',
				range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
				hidden: 'invisible'
			}}
			components={{
				PreviousMonthButton: ({ ...props }) => {
					return (
						<Button {...props} className={cn(classNameForOptions({ variant: 'outline' }), 'z-10 h-8 w-8 border-neutral-100 p-0 opacity-50 hover:opacity-100 absolute inline-flex')}>
							<AngleLeftIcon className='h-2.5 w-2.5 text-neutral-700' />
						</Button>
					);
				},
				NextMonthButton: ({ ...props }) => (
					<Button
						{...props}
						className={cn(classNameForOptions({ variant: 'outline' }), ' z-10 h-8 w-8 border-neutral-100 p-0 opacity-50 hover:opacity-100 absolute inline-flex right-0 ')}
					>
						<AngleRightIcon className='h-2.5 w-2.5 text-neutral-700' />
					</Button>
				)
			}}
			startMonth={startMonth}
			endMonth={endMonth}
			hidden={hidden}
			{...props}
		/>
	);
}
Calendar.displayName = 'Calendar';

export { Calendar };
