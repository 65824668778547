'use client';

import { Fragment, type ReactNode } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, MenuItemsProps } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

interface DropdownItem {
	id: string;
	title: string;
	icon?: ReactNode | React.FC<{ className?: string }>;
	divider?: boolean;
	className?: string;
}

interface DropdownComponent {
	items: DropdownItem[];
	onClick: (id: string) => void;
	buttonComponent: ReactNode;
	buttonClassName?: string;
	menuListClassName?: string;
	containerClassName?: string;
	showArrow?: boolean;
	renderButtonRaw?: boolean;
	anchor?: MenuItemsProps['anchor'];
}

export default function Dropdown({
	items,
	onClick,
	buttonClassName = '',
	containerClassName = '',
	menuListClassName = '',
	buttonComponent,
	renderButtonRaw = false,
	showArrow = true,
	anchor = 'bottom end'
}: DropdownComponent) {
	return (
		<Menu as='div' className={`relative inline-block text-left ${containerClassName}`}>
			<>
				{renderButtonRaw ? (
					<MenuButton as={Fragment}>{buttonComponent}</MenuButton>
				) : (
					<MenuButton className={`inline-flex w-full justify-center ${buttonClassName}`}>
						{buttonComponent}
						{showArrow ? <ChevronDownIcon aria-hidden='true' className='-mr-1 ml-2 h-5 w-5' /> : null}
					</MenuButton>
				)}
			</>

			<MenuItems
				anchor={anchor}
				transition
				className={`origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${menuListClassName}`}
				portal
			>
				<div className='py-1 w-full'>
					{items.map(item => (
						<MenuItem key={item.id}>
							{({ focus }) => (
								<button
									className={classNames(
										focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'cursor-pointer group flex items-center px-4 py-2 text-sm flex-1 w-full',
										item.divider ? 'border-t border-gray-100' : '',
										item.className
									)}
									onClick={() => onClick(item.id)}
									type='button'
								>
									{item.icon && typeof item.icon === 'function' ? <item.icon className='text-neutral-400 h-4 w-4 mr-2' /> : item.icon}
									<span className='text-sm inline-flex'>{item.title}</span>
								</button>
							)}
						</MenuItem>
					))}
				</div>
			</MenuItems>
		</Menu>
	);
}
