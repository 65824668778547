import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgPrinterIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M6.49.48a3.01 3.01 0 0 0-3.004 3.006v4.507H6.49V3.486h10.644L18.51 4.86v3.132h3.004V4.86c0-.798-.314-1.563-.878-2.127L19.261 1.36A3 3 0 0 0 17.134.48zm12.02 16.527v4.507H6.49v-4.506zm3.004 1.503h1.503a1.5 1.5 0 0 0 1.502-1.503V12.5a3.01 3.01 0 0 0-3.005-3.005H3.486A3.01 3.01 0 0 0 .48 12.5v4.507a1.5 1.5 0 0 0 1.502 1.503h1.503v3.004A3.01 3.01 0 0 0 6.49 24.52h12.02a3.01 3.01 0 0 0 3.004-3.005zm-.75-6.386a1.127 1.127 0 1 1 0 2.254 1.127 1.127 0 0 1 0-2.254'
			/>
		</svg>
	);
});
export default memo(SvgPrinterIcon);
