import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarSeasonsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 26' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M8.36 1.672A1.17 1.17 0 0 0 7.187.5a1.17 1.17 0 0 0-1.172 1.172v1.953H4.063A3.13 3.13 0 0 0 .938 6.75v15.625A3.13 3.13 0 0 0 4.063 25.5h15.625a3.13 3.13 0 0 0 3.125-3.125V6.75a3.13 3.13 0 0 0-3.125-3.125h-1.954V1.672A1.17 1.17 0 0 0 16.562.5a1.17 1.17 0 0 0-1.171 1.172v1.953H8.359zM3.28 9.875H20.47v12.5c0 .43-.352.781-.782.781H4.063a.784.784 0 0 1-.78-.781zm2.344 4.688a1.563 1.563 0 1 0 3.125 0 1.563 1.563 0 0 0-3.125 0m10.938 3.124a1.563 1.563 0 1 0 0 3.126 1.563 1.563 0 0 0 0-3.125m-6.25-3.125c0 .65.522 1.172 1.171 1.172h5.47a1.17 1.17 0 0 0 1.171-1.171 1.17 1.17 0 0 0-1.172-1.172h-5.469a1.17 1.17 0 0 0-1.171 1.172m1.953 3.516h-5.47a1.17 1.17 0 0 0-1.171 1.172c0 .65.522 1.172 1.172 1.172h5.469a1.17 1.17 0 0 0 1.172-1.172 1.17 1.17 0 0 0-1.172-1.172'
			/>
		</svg>
	);
});
export default memo(SvgSidebarSeasonsIcon);
