'use client';

import { Fragment, useRef, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Button from './button';

export type ConfirmAlertType = 'destructive' | 'warning' | 'info';

interface ConfirmAlertProps {
	type?: ConfirmAlertType;
	confirmButtonTitle: string;
	cancelTitle?: string;
	title: string;
	description?: string;
	onClickConfirm: (...args: unknown[]) => void | Promise<void>;
	onClose: () => void;
	open: boolean;
	errorMessage?: string;
	isAsync?: boolean;
	children?: React.ReactNode;
}

export default function ConfirmAlert({
	open = false,
	// @deprecated
	isAsync = false,
	title,
	description,
	type = 'info',
	confirmButtonTitle,
	onClickConfirm,
	onClose,
	errorMessage,
	cancelTitle,
	children
}: ConfirmAlertProps) {
	const cancelButtonRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const onConfirm = async () => {
		if (isAsync) {
			setLoading(true);
			try {
				await onClickConfirm();
				setLoading(false);
			} catch (e) {
				if (errorMessage) {
					setError(errorMessage);
				}
				setLoading(false);
			}
		} else {
			onClickConfirm();
		}
	};

	const _onClose = () => {
		onClose();
		setLoading(false);
		setError(null);
	};

	return (
		<Transition.Root as={Fragment} show={open}>
			<Dialog as='div' className='relative z-[102]' initialFocus={cancelButtonRef} onClose={_onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-neutral-700 bg-opacity-50 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 overflow-y-auto'>
					<div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						>
							<DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
								<div className='sm:flex sm:items-start'>
									{type !== 'info' ? (
										<div
											className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mr-4 ${
												type === 'destructive' ? 'bg-red-100' : 'bg-orange-100'
											} sm:mx-0 sm:h-10 sm:w-10`}
										>
											<ExclamationTriangleIcon aria-hidden='true' className={`h-6 w-6 ${type === 'destructive' ? 'text-red-600' : 'text-orange-600'}`} />
										</div>
									) : null}
									<div className='mt-3 text-center sm:mt-0 sm:text-left'>
										<DialogTitle as='h3' className='text-[1.1rem] font-medium leading-6 text-neutral-800'>
											{title}
										</DialogTitle>
										{description ? (
											<div className='mt-2'>
												<p className='text-sm text-neutral-800'>{description}</p>
											</div>
										) : null}
										{children ? <div className='mt-2'>{children}</div> : null}
										{error ? <p className='text-xs block text-left mt-4 text-red-600'>{error}</p> : null}
									</div>
								</div>

								<div className='mt-5 sm:mt-4  sm:flex sm:flex-row-reverse'>
									<Button variant={type === 'info' ? 'black' : type} className='font-medium' loading={loading} onClick={onConfirm} size='md' type='button'>
										{confirmButtonTitle}
									</Button>
									<Button className='mr-3 font-medium shadow-none border border-neutral-100' onClick={_onClose} ref={cancelButtonRef} size='md' type='button'>
										{cancelTitle ? cancelTitle : 'Cancel'}
									</Button>
								</div>
							</DialogPanel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
