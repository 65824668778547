import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgPdfIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M3.861 22.266h2.254v2.253H3.86a3.01 3.01 0 0 1-3.005-3.005V3.486A3.01 3.01 0 0 1 3.861.48h7.77c.799 0 1.564.314 2.127.878l4.25 4.249c.563.563.877 1.328.877 2.127v7.019h-2.253V7.993h-3.756a1.5 1.5 0 0 1-1.503-1.503V2.734H3.861a.753.753 0 0 0-.751.752v18.028c0 .414.338.752.751.752m5.259-5.259h1.502a2.63 2.63 0 0 1 2.63 2.63 2.63 2.63 0 0 1-2.63 2.629h-.751v1.502a.753.753 0 0 1-.751.751.753.753 0 0 1-.752-.75v-6.011c0-.413.338-.75.752-.75m1.502 3.756c.624 0 1.127-.502 1.127-1.127 0-.624-.503-1.126-1.127-1.126h-.751v2.253zm4.507-3.756h1.503a2.254 2.254 0 0 1 2.253 2.254v3.005a2.254 2.254 0 0 1-2.253 2.253h-1.503a.753.753 0 0 1-.751-.75v-6.01c0-.414.338-.752.751-.752m1.503 6.01a.753.753 0 0 0 .75-.751V19.26a.753.753 0 0 0-.75-.751h-.752v4.507zm3.756-5.259c0-.413.338-.75.75-.75h2.254c.414 0 .752.337.752.75a.753.753 0 0 1-.752.752H21.89v1.502h1.502c.414 0 .752.338.752.751a.753.753 0 0 1-.752.751H21.89v2.254a.753.753 0 0 1-.751.751.753.753 0 0 1-.751-.75v-6.011'
			/>
		</svg>
	);
});
export default memo(SvgPdfIcon);
