import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgFileXIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M3.606 22.266a.753.753 0 0 1-.751-.752V3.486c0-.414.338-.752.75-.752h7.513V6.49a1.5 1.5 0 0 0 1.502 1.503h3.756v13.521a.753.753 0 0 1-.751.752zm0-21.785A3.01 3.01 0 0 0 .6 3.486v18.028a3.01 3.01 0 0 0 3.005 3.005h12.019a3.01 3.01 0 0 0 3.005-3.005V7.734c0-.798-.315-1.563-.878-2.126l-4.254-4.25a3 3 0 0 0-2.122-.877zM5.812 11.7a1.12 1.12 0 0 0 0 1.592L8.02 15.5l-2.207 2.207a1.126 1.126 0 0 0 1.592 1.592l2.207-2.207 2.206 2.206a1.126 1.126 0 0 0 1.592-1.592L11.202 15.5l2.207-2.207a1.126 1.126 0 0 0-1.592-1.592L9.611 13.91l-2.207-2.207a1.12 1.12 0 0 0-1.592 0'
			/>
		</svg>
	);
});
export default memo(SvgFileXIcon);
