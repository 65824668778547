import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarProductsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 24' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M17.167 2.972c.382 0 .694.313.694.695v5.555h2.083V3.667A2.78 2.78 0 0 0 17.167.889H8.833a2.78 2.78 0 0 0-2.777 2.778v5.555h2.083V3.667c0-.382.312-.695.694-.695h2.43V5.75c0 .382.313.694.695.694h2.084a.696.696 0 0 0 .694-.694V2.972zm-2.448 20.14h8.003a2.78 2.78 0 0 0 2.778-2.779V13.39a2.78 2.78 0 0 0-2.778-2.778H14.72a4.13 4.13 0 0 1 1.002 2.083h1.098v2.778c0 .382.313.695.695.695h2.083a.696.696 0 0 0 .695-.695v-2.778h2.43c.382 0 .695.313.695.695v6.944a.696.696 0 0 1-.695.695h-7a4.17 4.17 0 0 1-1.003 2.083M11.61 12.693c.382 0 .695.313.695.695v6.944a.696.696 0 0 1-.695.695H3.278a.696.696 0 0 1-.695-.695V13.39c0-.382.313-.695.695-.695h2.43v2.778c0 .382.313.695.695.695h2.083a.696.696 0 0 0 .695-.695v-2.778zm-8.333-2.083A2.78 2.78 0 0 0 .5 13.39v6.944a2.78 2.78 0 0 0 2.778 2.778h8.333a2.78 2.78 0 0 0 2.778-2.778V13.39a2.78 2.78 0 0 0-2.778-2.778z'
			/>
		</svg>
	);
});
export default memo(SvgSidebarProductsIcon);
