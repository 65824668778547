'use client';

import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

interface DropdownSelectComponent<T> {
	items: T[];
	getName: string | ((item: T) => string);
	getValue: string | ((item: T) => string);
	onChange: (value: T) => void;
	selected: T | null;
	className?: string;
	containerClassName?: string;
	renderIcon?: (item: T | null) => JSX.Element;
	renderButton?: (item: T | null) => ReactNode;
	noSelectionTitle?: string;
	arrowDirection?: 'up' | 'down' | 'up-down';
}

export default function DropdownSelect<T>({
	items,
	getName,
	getValue,
	selected: _selected,
	onChange,
	renderIcon,
	className = '',
	containerClassName = '',
	arrowDirection = 'up-down',
	noSelectionTitle = '',
	renderButton
}: DropdownSelectComponent<T>) {
	const [selected, setSelected] = useState<T | null>(_selected);

	useEffect(() => {
		setSelected(_selected);
	}, [_selected]);

	const _onChange = (item: T) => {
		onChange(item);
		setSelected(item);
	};

	let selectedTitle: string;

	if (selected) {
		selectedTitle = typeof getName === 'string' ? (selected[getName as keyof T] as string) : getName(selected);
	} else {
		selectedTitle = noSelectionTitle;
	}

	return (
		<Listbox onChange={_onChange} value={selected}>
			{({ open }) => (
				<div className={`relative ${containerClassName}`}>
					<ListboxButton
						className={`relative px-2 flex items-center cursor-pointer rounded-md border border-gray-300 bg-white py-2 text-left focus:outline-none  sm:text-sm ${className}`}
					>
						{renderButton ? (
							renderButton(selected)
						) : (
							<>
								{renderIcon ? <div className='mr-2'>{renderIcon(selected)}</div> : null}
								<span className={classNames(selected ? 'text-default' : 'text-neutral-600', 'block truncate')}>{selectedTitle}</span>
								<span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
									{arrowDirection === 'up' && <ChevronUpIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />}
									{arrowDirection === 'down' && <ChevronDownIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />}
									{arrowDirection === 'up-down' && <ChevronUpDownIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />}
								</span>
							</>
						)}
					</ListboxButton>

					<Transition as={Fragment} leave='transition ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0' show={open}>
						<ListboxOptions
							as='div'
							className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
						>
							{/* {noSelectionTitle && (
									<Listbox.Option
										key={noSelectionTitle}
										className={({ active }) =>
											classNames(active ? 'text-white bg-primary-600' : 'text-gray-900', 'relative block cursor-pointer select-none py-2 pl-3 pr-9')
										}
										value={null}
									>
										{({ selected, active }) => (
											<div className="flex items-center">
												<span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{noSelectionTitle}</span>
											</div>
										)}
									</Listbox.Option>
								)} */}
							{items.map(item => (
								<ListboxOption
									className={({ active }) => classNames(active ? 'bg-neutral-50' : 'text-gray-900', 'relative block cursor-pointer select-none py-2 px-2')}
									key={typeof getValue === 'string' ? (item[getValue as keyof T] as string) : getValue(item)}
									value={item}
								>
									{({ selected: __selected, active }) => (
										<div className='flex items-center'>
											{renderIcon ? <div className='mr-2'>{renderIcon(item)}</div> : null}
											<span className={classNames('font-normal block truncate')}>{typeof getName === 'string' ? (item[getName as keyof T] as string) : getName(item)}</span>

											{__selected ? (
												<span className={classNames(active ? '' : 'text-black', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
													<CheckIcon aria-hidden='true' className='h-5 w-5' />
												</span>
											) : null}
										</div>
									)}
								</ListboxOption>
							))}
						</ListboxOptions>
					</Transition>
				</div>
			)}
		</Listbox>
	);
}
