import { HTMLProps } from 'react';
import { cn } from '../utils';

type Headers = (string | [string, string])[];

interface BaseProps {
	children?: React.ReactNode;
	className?: string;
	paddingLeft?: boolean;
}

interface TableProps extends Partial<BaseProps> {
	headers?: Headers;
	headerClassName?: string;
	allowOverflow?: boolean;
}

interface TableHeaderProps extends Partial<BaseProps> {
	headers?: Headers;
	itemClassName?: string;
}

type RowProps = HTMLProps<HTMLTableRowElement> & BaseProps;

export function Row({ children, ...props }: RowProps) {
	return <tr {...props}>{children}</tr>;
}

// export function HeaderRow({ children, className }: BaseProps) {
// 	return (
// 		<Row className={cn('border-b border-neutral-200', className)}>
// 			<th scope='col' className='py-3.5 px-3 text-left text-xs font-semibold text-neutral-700 uppercase px-3'>
// 				{children}
// 			</th>
// 		</Row>
// 	);
// }

export function Cell({ children, className, paddingLeft, ...rest }: BaseProps & HTMLProps<HTMLTableCellElement>) {
	return (
		<td className={cn('whitespace-nowrap px-3 py-4 text-sm', paddingLeft ? '' : 'px-3', className)} {...rest}>
			{children}
		</td>
	);
}

export function HeaderCell({ children, className, paddingLeft, ...rest }: BaseProps & HTMLProps<HTMLTableCellElement>) {
	return (
		<th className={cn('whitespace-nowrap px-3 py-2 text-sm font-semibold text-neutral-700 text-left', paddingLeft ? '' : 'px-3', className)} {...rest}>
			{children}
		</th>
	);
}

export function SubHeader({ children, paddingLeft, ...rest }: BaseProps & HTMLProps<HTMLTableCellElement>) {
	return (
		<Row>
			<th {...rest} className={cn('border-b-0 pt-4 px-3 text-left text-xs font-medium text-subtitle uppercase', paddingLeft ? '' : 'px-3')}>
				{children}
			</th>
		</Row>
	);
}

export function Header({ headers, children, paddingLeft, itemClassName = '', className }: TableHeaderProps) {
	return (
		<thead className={`overflow-hidden ${className}`}>
			<Row className='overflow-hidden [&>:first-child]:rounded-l-md [&>:last-child]:rounded-r-md'>
				{headers
					? headers.map(header => (
							<th key={Array.isArray(header) ? header[0] : header} scope='col' className={cn(`py-3.5 px-3 text-left text-xs ${itemClassName}`, paddingLeft ? '' : 'px-3')}>
								<span className='uppercase font-semibold text-neutral-700'>{Array.isArray(header) ? header[0] : header}</span>
								{headers.some(h => Array.isArray(h)) ? (
									<span style={{ opacity: Array.isArray(header) ? 1 : 0 }} className='block text-subtitle text-xs text-subtitle font-normal'>
										{Array.isArray(header) ? header[1] : '&nbsp;'}
									</span>
								) : null}
							</th>
						))
					: children}
			</Row>
		</thead>
	);
}

export function Body({ children, className = '' }: BaseProps) {
	return <tbody className={`!border-t-0 divide-y divide-neutral-50 ${className}`}>{children}</tbody>;
}

export function Table({ children, headers, headerClassName, allowOverflow }: TableProps) {
	return (
		<div className=''>
			<div className='mt-4 flow-root'>
				<div className={cn(allowOverflow ? '' : 'overflow-x-auto', '-my-2 overflow-x-auto')}>
					<div className='inline-block min-w-full py-2 align-middle'>
						<table className='min-w-full divide-y divide-neutral-50'>
							{headers ? <Header className={headerClassName} headers={headers} /> : null}
							{children ? children : null}
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
