'use client';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import Spinner from './spinner';
import { cn } from '../utils';
import { Slot, Slottable } from '@radix-ui/react-slot';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
	variant?: ButtonVariant;
	size?: ButtonSize;
	loading?: boolean;
	icon?: ReactNode;
	asChild?: boolean;
}

export type ButtonVariant = keyof typeof classNamesForVariant;
type ButtonSize = keyof typeof classNamesForSize;

const classNamesForVariant = {
	white: 'text-dark bg-white hover:bg-gray-50 shadow-sm',
	primary: 'text-white bg-primary hover:bg-primary/90 shadow-sm',
	outline: 'text-black border border-black bg-clear hover:bg-accent-50 shadow-sm',
	black: 'text-white bg-black hover:bg-black/90 shadow-sm',
	blue: 'text-white bg-primary hover:bg-primary/90 shadow-sm',
	info: 'text-white bg-primary-500 hover:bg-primary-500/90 shadow-sm',
	warning: 'text-white bg-yellow-500 hover:bg-yellow-500/90 shadow-sm',
	red: 'text-white bg-primary-red shadow-sm',
	destructive: 'text-sup-red-50 bg-sup-red-400 hover:bg-primary-red/90 shadow-sm',
	facebook: 'text-white bg-facebook shadow-sm',
	'hollow-blue': 'text-primary border border-primary bg-clear shadow-sm',
	success: 'text-white bg-sup-green-600 hover:bg-sup-green-600/95 shadow-sm',
	successLight: 'text-sup-green-900 bg-sup-green-50 hover:bg-sup-green-50 shadow-sm',
	successOutline: 'text-sup-green-600 border border-sup-green-600 bg-clear hover:bg-sup-green-50 shadow-sm',
	neutral: 'bg-neutral-100 text-neutral-700 hover:bg-neutral-100/80 shadow-sm',
	neutralOutline: 'text-neutral-600 border border-neutral-600 bg-clear hover:bg-neutral-100/80 shadow-sm',
	'neutral-dark': 'bg-neutral-800 text-neutral-50 hover:bg-neutral-800/90 shadow-sm',
	transparent: 'bg-transparent text-neutral-700 hover:bg-neutral-100/80',
	text: 'bg-transparent text-primary hover:text-primary/70',
	icon: 'bg-transparent text-inherit hover:bg-neutral-50/50',
	'text-neutral': 'bg-transparent text-neutral-700 hover:text-neutral-900',

	// Deprecated, same as neutral
	'neutral-400': 'bg-neutral-100 text-neutral-700 hover:bg-neutral-100/80',
	'neutral-500': 'bg-neutral-100 text-neutral-700 hover:bg-neutral-100/80'
};

const classNamesForSize = {
	xs: (v?: ButtonVariant) => (v?.includes('text') ? 'text-xs' : 'px-3 py-1 text-xs rounded-md'),
	sm: (v?: ButtonVariant) => (v?.includes('text') ? 'text-[.8rem]' : 'px-3 py-1 text-[.8rem] rounded-md'),
	md: (v?: ButtonVariant) => (v?.includes('text') ? 'text-sm' : 'px-4 py-2 text-sm rounded-lg'),
	lg: (v?: ButtonVariant) => (v?.includes('text') ? 'text-base' : 'px-5 py-3 text-base rounded-lg'),
	xl: (v?: ButtonVariant) => (v?.includes('text') ? 'text-lg' : 'px-6 py-4 text-lg rounded-lg')
};

export const classNameForOptions = ({ variant, size, className, disabled }: Pick<ButtonProps, 'size' | 'className' | 'disabled'> & { variant?: ButtonVariant }) => {
	return cn(
		'appearance-none flex whitespace-nowrap flex-row items-center inline-flex justify-center cursor-pointer disabled:cursor-no-drop shadow-inner-[0_35px_60px_-15px_rgba(0,0,0,0.3)]',
		variant ? classNamesForVariant[variant] : '',
		size ? classNamesForSize[size](variant) : '',
		className,
		disabled ? 'opacity-50 cursor-not-allowed' : ''
	);
};

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
	const { className, children, asChild, variant = 'white', size = 'md', loading = false, icon, ...rest } = props;

	const _className = classNameForOptions({ variant, size, className, disabled: loading });

	const Comp = asChild ? Slot : 'button';

	return (
		<Comp className={_className} disabled={loading || rest.disabled} ref={ref} {...rest}>
			{icon && !loading ? <span className='mr-2'>{icon}</span> : null}
			{loading ? <Spinner className='h-4 w-4 mr-2' /> : null}
			<Slottable>{children}</Slottable>
		</Comp>
	);
});
