'use client';

import React, { useState } from 'react';
import { format } from 'date-fns/format';
// import { da } from "@aiq/icons/components"

import { cn } from '../utils';
import Button from './button';
import { Calendar, type CalendarProps } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { FormTitle, classNameForInputProps } from './forms';
import { DateAfter, DateBefore, DateInterval, Matcher, Mode, Modifiers, PropsSingle, type DateRange, type DayPickerProps } from 'react-day-picker';

const defaultDateFormat = 'd. MMMM, yyyy';

export type DatePickerProps = Omit<CalendarProps, 'onChange'> & {
	title?: string;
	className?: string;
	name?: string;
	onChange?: (date: Date | DateRange | undefined) => void;
	minDate?: Date;
	maxDate?: Date;
	disabled?: boolean;
	showTimeSelect?: boolean;
	timeFormat?: string;
	value?: Date;
	dateFormat?: string;
	captionFormat?: string;
	placeholder?: string;
	error?: string;
	locale?: DayPickerProps['locale'];
};

export function DatePicker(props: DatePickerProps) {
	const [open, setOpen] = useState(false);

	const buttonClassNameOverride = 'justify-start hover:bg-input';

	const date = props.value;

	const ButtonComponent = (
		<div className={props.className}>
			{props.title ? <FormTitle title={props.title} /> : null}
			<Button
				type='button'
				variant='outline'
				className={classNameForInputProps({ title: props.title, className: buttonClassNameOverride, error: props.error, value: date ? format(date, 'PPP') : '' })}
			>
				{/* <CalendarIcon className="mr-2 h-4 w-4" /> */}
				{date ? format(date, props.dateFormat || 'PPP', { locale: props.locale }) : <span>{props.locale?.code === 'is' ? 'Veldu dagsetningu' : 'Choose a date'}</span>}
			</Button>
		</div>
	);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>{ButtonComponent}</PopoverTrigger>
			<PopoverContent align='start' className='w-auto p-0 bg-white'>
				{/*@ts-ignore*/}
				<Calendar
					mode='single'
					required={props.required}
					onSelect={(date: Date | DateRange | undefined) => {
						props.onChange?.(date);
						setOpen(false);
					}}
					/*@ts-ignore*/
					selected={date}
					captionFormat={props.captionFormat}
					startMonth={props.minDate}
					endMonth={props.maxDate}
					//@ts-ignore
					hidden={[props.minDate ? { before: props.minDate } : undefined, props.maxDate ? { after: props.maxDate } : undefined].filter(x => !!x)}
					defaultMonth={props.value}
					{...props}
					className=''
				/>
			</PopoverContent>
		</Popover>
	);
}
